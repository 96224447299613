import * as KatalMetrics from "@amzn/katal-metrics";
import KatalMetricsDriverArrayCollector from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector";
import KatalMetricsDriverConsoleLogJson from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import {
  KatalMonitoringDriver,
  KatalMonitoringDriverOptions,
} from "@amzn/katal-monitoring-aws-driver";

export enum MetricsType {
  // Transfer Widget metrics
  ADDING_CH_CONNECTION_ERROR = "addingChConnectionError",
  TRANSFER_CASE_TRIGGER = "transferCaseTrigger",
  AGENT_SEARCH_ERROR = "agentSearchError",
  GET_WORKITEM_ID_ERROR = "getWorkItemIdError",
  TRANSFER_BY_ATTRIBUTE_ERROR = "transferByAttributeError",
  TRANSFER_BY_EMAIL_ERROR = "transferByEmailError",
  TRANSFER_BY_AGENT_ERROR = "transferByAgentError",
  TRANSFER_BY_QUEUE_ERROR = "transferByQueueError",
  TRANSFER_BY_EXTERNAL_QUEUE_ERROR = "transferByExternalQueueError",
  TRANSFER_BY_QUEUE_DURATION = "transferByQueueDuration",
  TRANSFER_BY_EMAIL_DURATION = "transferByEmailDuration",
  TRANSFER_BY_ATTRIBUTE_DURATION = "transferByAttributeDuration",
  TRANSFER_BY_EXTERNAL_QUEUE_DURATION = "transferByExternalQueueDuration",
  TRANSFER_BY_AGENT_DURATION = "transferByAgentDuration",
  TRANSFER_BY_AGENT_COUNT = "transferByAgentCount",
  SINGLE_ATTRIBUTE_TRANSFER = "singleAttributeTransfer",
  MULTIPLE_ATTRIBUTE_TRANSFER = "multipleAttributeTransfer",
  PREDICTED_QUEUE_USED = "predictedQueueUsed",
  MANUAL_QUEUE_FALLBACK = "manualQueueFallback",
  PREDICTED_LANGUAGE_USED = "predictedLanguageUsed",
  PREDICTED_LANGUAGE_FALLBACK = "predictedLanguageFallback",
}

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === "test") {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== "production") {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    const sushiDriver = new KatalMetricsDriverSushi.Builder()
      .withDomainRealm("prod", "USAmazon")
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
    return new KatalMonitoringDriver(getMonitoringConfig(sushiDriver));
  }
};

const getMonitoringConfig = (
  sushiDriver: KatalMetricsDriverSushi,
): KatalMonitoringDriverOptions => {
  return {
    url: "https://zcns8gsu30.execute-api.us-east-1.amazonaws.com/prod/v1/monitoring",
    metricsSushiDriver: sushiDriver,
  };
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite("Paragon")
    .withServiceName("SPSEParagonAssociateFeatures")
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
